import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

function Card({ className, rounded, primary, pressable, children, ...rest }) {
  return (
    <div
      className={classNames(
        "card",
        { "card-rounded": rounded },
        { "card-primary": primary },
        { "card-pressable": pressable },
        className
      )}
      {...rest}
    >
      {children}
    </div>
  );
}

Card.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  primary: PropTypes.bool,
  pressable: PropTypes.bool,
  rounded: PropTypes.bool
};

Card.defaultProps = {
  className: undefined,
  children: null,
  primary: false,
  pressable: false,
  rounded: true
};

export default Card;
