import React, { Fragment, useState } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { noop } from "underscore";

import Button from "components/styled/button";

function Modal({ showTitle, title, content, isOpen, onClose, children }) {
  const [active, setActive] = useState(false);
  const openModal = () => setActive(true);
  const closeModal = () => {
    onClose();
    setActive(false);
  };

  return (
    <Fragment>
      {showTitle && (
        <small className="btn btn-link" onClick={openModal}>
          {title}
        </small>
      )}
      <div
        className={classNames("modal text-dark", { active: active || isOpen })}
      >
        <div className="modal-overlay" onClick={closeModal} />
        <div className="modal-container">
          {children}
          {!children && (
            <Fragment>
              <div className="modal-header">
                <div className="modal-title h5">{title}</div>
              </div>
              <div className="modal-body">
                <div className="content">{content}</div>
              </div>
              <div className="modal-footer">
                <Button onClick={closeModal}>Bestätigen</Button>
              </div>
            </Fragment>
          )}
        </div>
      </div>
    </Fragment>
  );
}

Modal.propTypes = {
  children: PropTypes.node,
  content: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  showTitle: PropTypes.bool,
  title: PropTypes.string
};

Modal.defaultProps = {
  children: null,
  content: "",
  isOpen: false,
  onClose: noop,
  showTitle: true,
  title: ""
};
export default Modal;
