import React from "react";

import Text from "components/styled/text";
import Layout from "components/styled/layout";
import Container from "components/styled/container";
import Columns from "components/styled/columns";
import Button from "components/styled/button";

export default function HappyHour() {
  return (
    <Layout className="bg-primary py-3">
      <Container>
        <Columns alignCenter>
          <Columns alignCenter column="col-sm-12 col-6">
            <Text
              column="col-sm-12 col-auto"
              tag="h2"
              uppercase
              bold
              className="mx-6 my-2"
            >
              Sushi menüs
            </Text>
            <Text className="hide-md" column="col-auto" large>
              /
            </Text>
            <Text
              column="col-sm-12"
              tag="h5"
              uppercase
              bold
              className="mx-6 my-2"
            >
              Happy hour preis
            </Text>
          </Columns>
          <Layout column="col-sm-12 col-6" className="mx-6 my-2">
            <Button
              primary
              withArrow
              onClick={() => window.open("/images/sushi-menus.jpg", "_blank")}
            >
              <Text>Jetzt Bestellen</Text>
            </Button>
          </Layout>
        </Columns>
      </Container>
    </Layout>
  );
}
