import React, { Fragment, useState, useEffect } from "react";
import PropTypes from "prop-types";
import queryString from "query-string";

import { getLocation, menuCategories } from "shared/constants";
import Checkbox from "components/styled/checkbox";
import Foods from "components/foods";
import Footer from "components/footer";
import Flex from "components/styled/flex";
import Header from "components/header";
import Section from "components/styled/section";
import Select from "components/styled/select";
import useScrollTo from "hooks/use-scroll-to";

import Hero from "./hero";

function Menu(props) {
  const city = getLocation(props.city);
  const [selectedCategory, selectCategory] = useState("menu");
  const [showLikedOnly, setShowLikedOnly] = useState(false);
  const [showPrice, setShowPrice] = useState(true);
  const toggleShowLikedOnly = () => setShowLikedOnly(!showLikedOnly);
  const toggleShowPrice = () => setShowPrice(!showPrice);

  useEffect(() => {
    const { category = "menu" } = queryString.parse(window.location.search);
    selectCategory(category);
  }, []);

  useScrollTo();

  return (
    <Fragment>
      <Header city={city}>
        <Hero city={city} />
      </Header>
      <Section
        headerLeft={
          <Select
            column="col-auto col-xs-6"
            options={menuCategories}
            onSelect={selectCategory}
            selected={selectedCategory}
          />
        }
        headerRight={
          <Flex column="col-auto">
            <Checkbox
              className="hide-sm mr-6"
              onChange={toggleShowLikedOnly}
              checked={showLikedOnly}
            >
              Favoriten
            </Checkbox>
            <Checkbox onChange={toggleShowPrice} checked={showPrice}>
              Außer Haus
            </Checkbox>
          </Flex>
        }
        id="categories"
        stickyHeader
        inverse
      >
        <Foods
          showLikedOnly={showLikedOnly}
          showPrice={showPrice}
          category={selectedCategory}
          city={city}
        />
      </Section>
      <Footer city={city} />
    </Fragment>
  );
}

Menu.propTypes = {
  city: PropTypes.oneOf([
    "bochum",
    "dortmund",
    "gelsenkirchen",
    "siegen",
    "lüdenscheid",
  ]),
};

Menu.defaultProps = {
  city: "bochum",
};

export default Menu;
