import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { Link as RouterLink } from "@reach/router";

import Icon from "components/styled/icon";

export default function Link({ className, to, href, withArrow, children }) {
  const Link = href ? "a" : RouterLink;
  return (
    <Link
      className={classNames("btn btn-link inverse", className)}
      href={href}
      to={to}
    >
      {children}
      {withArrow && <Icon className="btn-arrow" name="keyboard_backspace" />}
    </Link>
  );
}

Link.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  href: PropTypes.string,
  to: PropTypes.string,
  withArrow: PropTypes.bool,
};

Link.defaultProps = {
  children: null,
  className: undefined,
  href: undefined,
  to: undefined,
  withArrow: false,
};
