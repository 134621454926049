import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import noop from "lodash/noop";

import Icon from "components/styled/icon";

export default function Button({
  children,
  className,
  onClick,
  primary,
  secondary,
  withArrow
}) {
  return (
    <button
      className={classNames(
        "btn",
        { "btn-primary": primary, "btn-link": secondary },
        className
      )}
      onClick={onClick}
    >
      {children}
      {withArrow && <Icon className="btn-arrow" name="keyboard_backspace" />}
    </button>
  );
}

Button.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  onClick: PropTypes.func,
  primary: PropTypes.bool,
  secondary: PropTypes.bool,
  withArrow: PropTypes.bool
};

Button.defaultProps = {
  children: null,
  className: undefined,
  onClick: noop,
  primary: false,
  secondary: false,
  withArrow: false
};
