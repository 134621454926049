import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { noop, pick } from "underscore";

export default function Icon({
  active,
  className,
  color,
  inverse,
  name,
  secondary,
  size,
  onClick
}) {
  return (
    <i
      className={classNames(
        "material-icons",
        {
          "icon-active": active,
          "icon-inverse": inverse,
          "icon-secondary": secondary,
          "c-hand": !!onClick
        },
        className
      )}
      style={pick(
        {
          color: color,
          fontSize: size
        },
        Boolean
      )}
      onClick={name => onClick(name)}
    >
      {name}
    </i>
  );
}

Icon.propTypes = {
  active: PropTypes.bool,
  className: PropTypes.string,
  color: PropTypes.string,
  inverse: PropTypes.bool,
  name: PropTypes.string.isRequired,
  secondary: PropTypes.bool,
  onClick: PropTypes.func
};

Icon.defaultProps = {
  active: false,
  className: undefined,
  color: undefined,
  inverse: false,
  secondary: false,
  onClick: noop
};
