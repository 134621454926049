import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

function Flex({ children, className }) {
  return <div className={classNames("d-flex", className)}>{children}</div>;
}

Flex.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string
};

Flex.defaultProps = {
  children: null,
  className: undefined
};

export default Flex;
