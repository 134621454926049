import React, { Fragment, useState } from "react";
import PropTypes from "prop-types";
import { map } from "underscore";

import { dataPrivacy } from "shared/constants";
import { pages, locations } from "shared/constants";
import Columns from "components/styled/columns";
import DataPrivacyNotice from "components/data-privacy-notice";
import Icon from "components/styled/icon";
import Imprint from "components/imprint";
import Layout from "components/styled/layout";
import Link from "components/styled/link";
import Logo from "components/logo";
import Modal from "components/styled/modal";
import Section from "components/styled/section";
import Text from "components/styled/text";

function Footer({ city }) {
  const [isDataPrivacyModalOpen, setDataPrivacyModalOpen] = useState(false);
  const closeDataPrivacyModal = () => setDataPrivacyModalOpen(false);
  const openDataPrivacyModal = () => setDataPrivacyModalOpen(true);

  return (
    <Fragment>
      {city && <Layout className="footer-placeholder" show="sm" />}
      {city && (
        <Layout className="footer" show="sm">
          <Columns>
            {map(pages, ({ title, path, icon }) => (
              <Link key={title} to={`/${city}${path}`} className="footer-item">
                <Icon name={icon} secondary />
                <Text tag="p">{title}</Text>
              </Link>
            ))}
          </Columns>
        </Layout>
      )}
      <Section tag="footer" className="bg-inverse-light hide-sm pt-6">
        <Columns>
          <Link className="mt-2" column="col-auto" to="/">
            <Logo />
          </Link>
          <Layout className="pl-6 ml-6">
            <Columns gapless={false}>
              {map(locations, ({ address, tel, postcode, email }, location) => (
                <Layout column="col-md-6 my-4" key={location}>
                  <Text capitalize>{location}</Text>
                  <Text
                    className="pt-2"
                    tag="small"
                    slim
                    secondary
                    inverse
                    block
                  >
                    {address}
                    <br />
                    {postcode}
                  </Text>
                  <Text tag="small" secondary slim inverse block>
                    {tel}
                  </Text>
                  <Text tag="small" secondary slim inverse block>
                    {email}
                  </Text>
                </Layout>
              ))}
            </Columns>
            <Text tag="small" className="mr-6">
              Copyright © YUMINI 2019
            </Text>
            <Modal title="Datenschutz" content={dataPrivacy} />
            {city && (
              <Modal title="Impressum" content={<Imprint city={city} />} />
            )}
          </Layout>
        </Columns>
      </Section>
      <Modal
        showTitle={false}
        content={dataPrivacy}
        title="Datenschutz"
        isOpen={isDataPrivacyModalOpen}
        onClose={closeDataPrivacyModal}
      />
      <DataPrivacyNotice openDataPrivacy={openDataPrivacyModal} />
    </Fragment>
  );
}

Footer.propTypes = {
  city: PropTypes.string,
};

Footer.defaultProps = {
  city: undefined,
};

export default Footer;
