import React from "react";
import PropTypes from "prop-types";

function Logo({ height }) {
  return <img src="/images/logo.svg" height={height} alt="Yumini logo" />;
}

Logo.propTypes = {
  height: PropTypes.number,
};

Logo.defaultProps = {
  height: 48,
};

export default Logo;
