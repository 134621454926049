import React from "react";
import PropTypes from "prop-types";
import { map } from "underscore";
import classNames from "classnames";

import { pages, locations } from "shared/constants";
import Container from "components/styled/container";
import Icon from "components/styled/icon";
import Image from "components/styled/image";
import Layout from "components/styled/layout";
import Link from "components/styled/link";
import Logo from "components/logo";
import Text from "components/styled/text";

function Header({ city, children, fullHeight }) {
  return (
    <header className={classNames({ "full-height": fullHeight })}>
      <Container className="navbar" tag="nav">
        <Layout className="navbar-left">
          <Link to="/">
            <Logo />
          </Link>
        </Layout>
        <Layout className="navbar-center">
          {map(pages, ({ title, path }) => (
            <Link
              key={title}
              to={`/${city}${path}`}
              className="hide-sm text-slim"
            >
              {title}
            </Link>
          ))}
        </Layout>
        <Layout className="navbar-right">
          <Link href={locations[city].facebook}>
            <Image src="/images/facebook.svg" />
          </Link>
          <Layout hide="md">
            <Link href={locations[city].googleMapLink}>
              <Text capitalize tag="small">
                {locations[city].address.split(",")[0]}
              </Text>
              <Icon className="navbar-right-icon" name="map" />
            </Link>
          </Layout>
          <Layout absolute hide="sm" className="navbar-right-links">
            {map(Object.keys(locations), (location) => (
              <Link
                key={location}
                to={`/${location}`}
                className={classNames({
                  "navbar-right-link-active": location === city,
                })}
              >
                <Text
                  capitalize
                  bold={location === city}
                  tag="small"
                  primary={location === city}
                >
                  {location}
                </Text>
              </Link>
            ))}
          </Layout>
        </Layout>
      </Container>
      {children}
    </header>
  );
}

Header.propTypes = {
  city: PropTypes.string,
  children: PropTypes.node,
  fullHeight: PropTypes.bool,
};

Header.defaultProps = {
  city: undefined,
  children: null,
  fullHeight: false,
};

export default Header;
