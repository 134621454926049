import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

function Image({ src, alt, className, ...rest }) {
  return (
    <img
      src={src}
      alt={alt}
      className={classNames("img-responsive", className)}
      {...rest}
    />
  );
}

Image.propTypes = {
  alt: PropTypes.string,
  className: PropTypes.string,
  src: PropTypes.string
};

Image.defaultProps = {
  alt: "Image",
  className: undefined,
  src: ""
};

export default Image;
