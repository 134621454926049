import { useState } from "react";

const parseJSON = (str) => {
  try {
    return JSON.parse(str);
  } catch (e) {
    return undefined;
  }
};

export default function useLocalStorage(key) {
  const storage = window.sessionStorage;
  const [item, setItem] = useState(parseJSON(storage.getItem(key)));
  const updateItem = (next) => {
    setItem(next);
    storage.setItem(key, JSON.stringify(next));
  };

  return [item, updateItem];
}
