import React from "react";
import { map } from "underscore";
import { Link } from "@reach/router";

import BackgroundContainer from "components/styled/background-container";
import Card from "components/styled/card";
import Columns from "components/styled/columns";
import Image from "components/styled/image";
import Text from "components/styled/text";

const categories = [
  { name: "sushi", label: "sushi" },
  { name: "grill", label: "warm dishes" },
  { name: "salad", label: "salads | soups | noodles | rice" },
  { name: "dinner", label: "dinner only" },
  { name: "dessert", label: "dessert" },
  { name: "menu", label: "speisekarte" },
];

function MenuCategories() {
  return (
    <Columns gapless>
      {map(categories, ({ name, label }) => (
        <Card
          className="menu-category-card"
          rounded={false}
          column="col-xs-6 col-4"
          key={name}
        >
          <BackgroundContainer image={`menu/${name}.jpg`}>
            <Link
              to={`menu?category=${name}#categories`}
              className="menu-category-content"
            >
              <Image src={`/images/menu/${name}.svg`} width={64} />
              <Text block bold uppercase inverse className="my-5">
                {label}
              </Text>
            </Link>
          </BackgroundContainer>
        </Card>
      ))}
    </Columns>
  );
}

export default MenuCategories;
