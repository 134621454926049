import React from "react";
import PropTypes from "prop-types";

import Button from "components/styled/button";
import Container from "components/styled/container";
import Text from "components/styled/text";
import { locations } from "shared/constants";

function Hero({ city }) {
  const { reservation } = locations[city];
  const openReservation = () => window.open(reservation);

  return (
    <Container className="hero p-6">
      <Text tag="h2" slim className="py-6 mx-4 mt-6">
        Willkommen zu <Text bold>Yumini</Text>
        <Text block>Japanese Sushi & Grill</Text>
      </Text>

      <Button className="ml-4" withArrow onClick={openReservation}>
        <Text>Tisch Reservieren</Text>
      </Button>
    </Container>
  );
}

Hero.propTypes = {
  city: PropTypes.string,
};

Hero.defaultProps = {
  city: "bochum",
};

export default Hero;
