import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import Flex from "components/styled/flex";

function Checkbox({ className, onChange, checked, id, children }) {
  return (
    <Flex className={classNames("align-items-center", className)}>
      <input
        type="checkbox"
        name={id}
        id={id}
        className="checkbox"
        onChange={onChange}
        checked={checked}
      />
      <label onClick={onChange} htmlFor={id} className="checkbox-label"></label>
      {children}
    </Flex>
  );
}

Checkbox.propTypes = {
  checked: PropTypes.bool,
  className: PropTypes.string,
  onChange: PropTypes.func.isRequired
};

Checkbox.defaultProps = {
  className: undefined,
  checked: false
};

export default Checkbox;
