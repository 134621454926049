import React from "react";

import useLocalStorage from "hooks/use-local-storage";

import { dataPrivacyNotice } from "shared/constants";
import Layout from "components/styled/layout";
import Text from "components/styled/text";

function DataPrivacyNotice({ openDataPrivacy }) {
  const [dataPrivicyAgreed, setDataPrivicyAgreed] = useLocalStorage(
    "data-privacy-agreed"
  );
  const closeNotice = () => setDataPrivicyAgreed(true);

  if (!dataPrivicyAgreed) {
    return (
      <Layout className="data-privacy-notice">
        <Text block>{dataPrivacyNotice}</Text>
        <Layout className="data-privacy-notice-buttons">
          <Text
            className="mr-4"
            role="button"
            tabIndex="0"
            onClick={closeNotice}
          >
            Ok
          </Text>
          <Text role="button" tabIndex="0" onClick={openDataPrivacy}>
            Datenschutzerklärung
          </Text>
        </Layout>
      </Layout>
    );
  }

  return null;
}

export default DataPrivacyNotice;
