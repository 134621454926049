import React from "react";

import BackgroundContainer from "components/styled/background-container";
import Layout from "components/styled/layout";
import Logo from "components/logo";
import Text from "components/styled/text";

import Locations from "./locations";

const styles = {
  root: {
    backgroundColor: "rgb(3, 5, 4)",
    display: "flex",
    flexDirection: "column",
  },
  header: {
    display: "flex",
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
  },
  container: {
    backgroundColor: "rgb(3, 5, 4)",
    display: "flex",
    flexDirection: "column",
  },
  spacer: {
    height: 30,
  },
};

function Index() {
  return (
    <Layout className="full-height" style={styles.root}>
      <BackgroundContainer
        image="index-bg.jpg"
        size="contain"
        position="center 30px"
        style={styles.root}
        className="full-height py-6"
      >
        <Layout style={styles.header}>
          <Logo height={80} />
        </Layout>
        <Text
          center
          inverse
          block
          uppercase
          tag="small"
          className="text-spacing py-4"
        >
          Japanese Sushi & Grill
        </Text>
        <Locations />
        <Layout hide="sm" style={styles.spacer} />
      </BackgroundContainer>
    </Layout>
  );
}

export default React.memo(Index);
