import React from "react";
import PropTypes from "prop-types";
import { contains, filter, map, without, union, compact } from "underscore";

import Card from "components/styled/card";
import Columns from "components/styled/columns";
import Icon from "components/styled/icon";
import Image from "components/styled/image";
import Label from "components/styled/label";
import Layout from "components/styled/layout";
import Text from "components/styled/text";
import useLocalStorage from "hooks/use-local-storage";

import bochum from "shared/menu/bochum";
import dortmund from "shared/menu/dortmund";
import gelsenkirchen from "shared/menu/gelsenkirchen";
import siegen from "shared/menu/siegen";
import lüdenscheid from "shared/menu/lüdenscheid";

const addOnlineOrderId = ({ id, ...rest }) => ({
  ...rest,
  id,
  onlineOrderId: id + 500,
});

const menus = {
  bochum,
  dortmund,
  gelsenkirchen,
  siegen: map(siegen, addOnlineOrderId),
  lüdenscheid: map(lüdenscheid, addOnlineOrderId),
};

const COLUMN_WIDTH = "col-2 col-md-3 col-xs-6";

const intl = new Intl.NumberFormat("de-DE", {
  style: "currency",
  currency: "EUR",
});

function Foods({ city, category, showPrice, showLikedOnly }) {
  const [likedDishes, setLikedDishes] = useLocalStorage("liked-dishes");
  const filtedFoods = filter(
    menus[city],
    (food) =>
      (!showLikedOnly || contains(likedDishes, food.id)) &&
      (food.category === category ||
        category === "menu" ||
        (category === "dinner" && food.dinnerOnly))
  );

  return (
    <Columns gapless={false}>
      {map(
        filtedFoods,
        ({ category, descr, dinnerOnly, id, name, onlineOrderId, price }) => {
          const liked = contains(likedDishes, id);
          const foodId = onlineOrderId || id;
          const foodPrice = showPrice || category === "side" ? price : 0;
          const foodImage = `/images/sushi/${city}/${id}.png`;
          const onClickFood = () =>
            setLikedDishes(
              liked
                ? without(likedDishes, id)
                : compact(union(likedDishes, [id]))
            );

          return (
            <Card column={COLUMN_WIDTH} className="mb-4" key={foodId}>
              <Layout absolute style={{ top: 10, left: 10 }}>
                <Label inverse>{foodId}</Label>
              </Layout>
              <Image className="py-6" src={foodImage} alt={name} />
              <Layout className="px-4">
                <Text capitalize tag="h6">
                  {name}
                </Text>
                <Text secondary inverse>
                  {descr}
                </Text>
                {dinnerOnly && (
                  <Layout absolute style={{ top: 10, right: 10 }}>
                    <Label primary>Dinner Only</Label>
                  </Layout>
                )}
                <Columns alignCenter className="py-3">
                  <Text secondary inverse tag="h6">
                    {intl.format(foodPrice)}
                  </Text>
                  <Icon
                    column="col-auto"
                    secondary={!liked}
                    active={liked}
                    name={liked ? "favorite" : "favorite_border"}
                    onClick={onClickFood}
                  />
                </Columns>
              </Layout>
            </Card>
          );
        }
      )}
    </Columns>
  );
}

Foods.propTypes = {
  city: PropTypes.oneOf([
    "bochum",
    "dortmund",
    "gelsenkirchen",
    "siegen",
    "lüdenscheid",
  ]),
  category: PropTypes.oneOf([
    "menu",
    "dinner",
    "dessert",
    "salad",
    "sauce",
    "side",
    "sushi",
    "grill",
  ]),
  masonry: PropTypes.bool,
  showPrice: PropTypes.bool,
  showLikedOnly: PropTypes.bool,
};

Foods.defaultProps = {
  city: "bochum",
  category: "menu",
  showPrice: false,
  showLikedOnly: false,
};

export default Foods;
