import React from "react";
import { Router } from "@reach/router";

import Index from "pages/index";
import Home from "pages/home";
import Menu from "pages/menu";

function App() {
  return (
    <Router>
      <Index path="/" />
      <Home path="/:city" />
      <Menu path="/:city/menu" />
    </Router>
  );
}

export default App;
