import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

export default function Text({
  block,
  bold,
  capitalize,
  center,
  children,
  className,
  inverse,
  large,
  secondary,
  slim,
  tag,
  uppercase,
  primary,
  ...rest
}) {
  return React.createElement(
    tag,
    {
      className: classNames(
        {
          "d-block": block,
          "text-bold": bold,
          "text-slim": slim,
          "text-capitalize": capitalize,
          "text-center": center,
          "text-inverse-muted": secondary && inverse,
          "text-large": large,
          "text-light": inverse && !secondary,
          "text-muted": secondary && !inverse,
          "text-primary": primary,
          "text-uppercase": uppercase
        },
        className
      ),
      ...rest
    },
    children
  );
}

Text.propTypes = {
  block: PropTypes.bool,
  capitalize: PropTypes.bool,
  center: PropTypes.bool,
  children: PropTypes.node,
  className: PropTypes.string,
  id: PropTypes.string,
  inverse: PropTypes.bool,
  large: PropTypes.bool,
  primary: PropTypes.bool,
  secondary: PropTypes.bool,
  slim: PropTypes.bool,
  tag: PropTypes.string,
  uppercase: PropTypes.bool
};

Text.defaultProps = {
  block: false,
  capitalize: false,
  center: false,
  children: null,
  className: undefined,
  id: undefined,
  inverse: false,
  large: false,
  primary: false,
  secondary: false,
  slim: false,
  tag: "span",
  uppercase: false
};
