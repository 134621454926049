import React from "react";
import PropTypes from "prop-types";

import BackgroundContainer from "components/styled/background-container";
import Container from "components/styled/container";
import Text from "components/styled/text";
import Link from "components/styled/link";

function OrderByTablet({ city }) {
  return (
    <BackgroundContainer
      className="py-6"
      image="sushi-icon.png"
      size={1000}
      position="top right"
    >
      <Container size="sm" className="text-center my-6 py-6">
        <Text tag="h3">Bestellen per Tablet</Text>
        <Text large tag="p" className="pb-6">
          Genießen Sie unser außergewöhnliches japanisches All-you-can-eat
          Buffet mit über 100 warmen und kalten Speisen, Suppen, Salaten,
          Fingerfood, Sushi und Teppanyaki.
        </Text>
        <Link withArrow to={`/${city}/menu`}>
          <Text>Speisekarte</Text>
        </Link>
      </Container>
    </BackgroundContainer>
  );
}

OrderByTablet.propTypes = {
  city: PropTypes.string
};

OrderByTablet.defaultProps = {
  city: "bochum"
};

export default OrderByTablet;
