import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

function Label({ children, primary, inverse }) {
  return (
    <small
      className={classNames("label", {
        "label-primary": primary,
        "label-inverse": inverse
      })}
    >
      {children}
    </small>
  );
}

Label.propTypes = {
  children: PropTypes.node.isRequired,
  primary: PropTypes.bool,
  inverse: PropTypes.bool
};

Label.defaultProps = {
  inverse: false,
  primary: false
};

export default Label;
