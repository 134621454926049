import React from "react";
import PropTypes from "prop-types";
import { map } from "underscore";

export default function Select({ className, options, onSelect, selected }) {
  const onChange = event => onSelect(event.target.value);

  return (
    <select className={className} onChange={onChange} value={selected}>
      {map(options, ({ value, label }) => (
        <option value={value} key={value}>
          {label}
        </option>
      ))}
    </select>
  );
}

Select.propTypes = {
  className: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string
    })
  ),
  onSelect: PropTypes.func.isRequired,
  selected: PropTypes.string
};

Select.defaultProps = {
  className: undefined,
  options: [],
  selected: undefined
};
