import React, { cloneElement, Children } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

export default function Columns({
  alignCenter,
  className,
  gapless,
  children,
  ...rest
}) {
  return (
    <div
      className={classNames("columns", className, {
        "align-items-center": alignCenter,
        "col-gapless": gapless
      })}
      {...rest}
    >
      {Children.map(children, child => {
        if (child) {
          return (
            <div className={classNames("column", child.props.column)}>
              {cloneElement(child, { column: undefined })}
            </div>
          );
        }

        return child;
      })}
    </div>
  );
}

Columns.propTypes = {
  alignCenter: PropTypes.bool,
  className: PropTypes.string,
  gapless: PropTypes.bool,
  children: PropTypes.node
};

Columns.defaultProps = {
  alignCenter: false,
  className: undefined,
  gapless: true,
  children: null
};
