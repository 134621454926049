import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

function Layout({ children, absolute, hide, show, className, ...rest }) {
  return (
    <div
      className={classNames(className, {
        [`hide-${hide}`]: hide,
        [`show-${show}`]: show,
        "p-absolute": absolute
      })}
      {...rest}
    >
      {children}
    </div>
  );
}

Layout.propTypes = {
  absolute: PropTypes.bool,
  children: PropTypes.node,
  className: PropTypes.string,
  hide: PropTypes.oneOf(["xs", "sm", "md", "lg", "xl"]),
  show: PropTypes.oneOf(["xs", "sm", "md", "lg", "xl"])
};

Layout.defaultProps = {
  absolute: false,
  children: null,
  className: undefined,
  hide: undefined,
  show: undefined
};

export default Layout;
