export default [
  {
    name: "Sake",
    price: 1.8,
    category: "sushi",
    id: 1,
    descr: "Lachs",
    dinnerOnly: false,
  },
  {
    name: "Maguro",
    price: 2,
    category: "sushi",
    id: 2,
    descr: "Thunfisch",
    dinnerOnly: false,
  },
  {
    name: "Mutsu",
    price: 1.6,
    category: "sushi",
    id: 3,
    descr: "Butterfisch",
    dinnerOnly: false,
  },
  // {
  //   name: "Mamakari Suzuke",
  //   price: 1.6,
  //   category: "sushi",
  //   id: 4,
  //   descr: "Sardinellen Filet, mariniert",
  //   dinnerOnly: false,
  // },
  {
    name: "Ebi",
    price: 2,
    category: "sushi",
    id: 5,
    descr: "Garnelen",
    dinnerOnly: false,
  },
  {
    name: "Kanikama",
    price: 1.6,
    category: "sushi",
    id: 6,
    descr: "Surimi",
    dinnerOnly: false,
  },
  {
    name: "Avocado",
    price: 1.6,
    category: "sushi",
    id: 7,
    descr: "Avocado",
    dinnerOnly: false,
  },
  {
    name: "Tamago",
    price: 1.6,
    category: "sushi",
    id: 8,
    descr: "Omlett",
    dinnerOnly: false,
  },
  {
    name: "Kinusaya",
    price: 1.5,
    category: "sushi",
    id: 9,
    descr: "Pochierte Kaiserschoten",
    dinnerOnly: false,
  },
  {
    name: "Inari",
    price: 1.8,
    category: "sushi",
    id: 10,
    descr: "Tofutasche",
    dinnerOnly: false,
  },
  {
    name: "Tako",
    price: 2,
    category: "sushi",
    id: 12,
    descr: "Oktopus",
    dinnerOnly: false,
  },
  {
    name: "Hokkigai",
    price: 2.2,
    category: "sushi",
    id: 14,
    descr: "Nordmuscheln",
    dinnerOnly: true,
  },
  {
    name: "Hotategai",
    price: 2.8,
    category: "sushi",
    id: 15,
    descr: "Jakobsmuscheln",
    dinnerOnly: true,
  },
  {
    name: "Unagi",
    price: 2.8,
    category: "sushi",
    id: 16,
    descr: "Aal gegrillt",
    dinnerOnly: true,
  },
  {
    name: "Sake Aburi",
    price: 2,
    category: "sushi",
    id: 17,
    descr: "Lachs flambiert",
    dinnerOnly: true,
  },
  {
    name: "Maguro Aburi",
    price: 2.2,
    category: "sushi",
    id: 18,
    descr: "Thunfisch flambiert",
    dinnerOnly: true,
  },
  {
    name: "Goma Wakame",
    price: 2,
    category: "sushi",
    id: 19,
    descr: "Algen",
    dinnerOnly: false,
  },
  {
    name: "Tsuna-Gunkan",
    price: 2.5,
    category: "sushi",
    id: 20,
    descr: "Thunfisch gekocht,scharf",
    dinnerOnly: false,
  },
  {
    name: "Mutsu Aburi",
    price: 1.9,
    category: "sushi",
    id: 21,
    descr: "Butterfisch, flambiert",
    dinnerOnly: false,
  },
  {
    name: "Red-Massago-Gunkan",
    price: 2.5,
    category: "sushi",
    id: 22,
    descr: "Fischrogen",
    dinnerOnly: false,
  },
  {
    name: "Green-Massago-Gunkan",
    price: 2.5,
    category: "sushi",
    id: 23,
    descr: "Fischrogen",
    dinnerOnly: false,
  },
  // {
  //   name: "Mamakari Suzuke Aburi",
  //   price: 1.9,
  //   category: "sushi",
  //   id: 24,
  //   descr: "Sardinellen Filet, flambiert",
  //   dinnerOnly: true,
  // },
  {
    name: "Ikura",
    price: 3.9,
    category: "sushi",
    id: 26,
    descr: "Lachskaviar",
    dinnerOnly: true,
  },
  {
    name: "Kappa Maki",
    price: 1.7,
    category: "sushi",
    id: 27,
    descr: "Gurken",
    dinnerOnly: false,
  },
  {
    name: "Kanpyo Maki",
    price: 1.5,
    category: "sushi",
    id: 28,
    descr: "Kürbis",
    dinnerOnly: false,
  },
  {
    name: "Avocado Maki",
    price: 1.8,
    category: "sushi",
    id: 29,
    descr: "Avocado",
    dinnerOnly: false,
  },
  {
    name: "Schinko Maki",
    price: 1.5,
    category: "sushi",
    id: 30,
    descr: "eingelegter Rettich",
    dinnerOnly: false,
  },
  {
    name: "Tamago Maki",
    price: 1.6,
    category: "sushi",
    id: 31,
    descr: "Omlett",
    dinnerOnly: false,
  },
  {
    name: "Kani Maki",
    price: 1.7,
    category: "sushi",
    id: 32,
    descr: "Surimi",
    dinnerOnly: false,
  },
  {
    name: "Sake Maki",
    price: 1.8,
    category: "sushi",
    id: 33,
    descr: "Lachs",
    dinnerOnly: false,
  },
  {
    name: "Mutsu Maki",
    price: 1.7,
    category: "sushi",
    id: 34,
    descr: "Butterfisch,scharf",
    dinnerOnly: false,
  },
  {
    name: "Tekka Maki",
    price: 2,
    category: "sushi",
    id: 35,
    descr: "Thunfisch",
    dinnerOnly: false,
  },
  {
    name: "Tsuna Maki",
    price: 1.9,
    category: "sushi",
    id: 36,
    descr: "Thunfisch,gekocht,scharf",
    dinnerOnly: false,
  },
  {
    name: "Ebi Maki",
    price: 2.1,
    category: "sushi",
    id: 37,
    descr: "Garnelen",
    dinnerOnly: false,
  },
  {
    name: "Fresh Cheese Roll",
    price: 1.9,
    category: "sushi",
    id: 39,
    descr: "Frischkäse & Gurke",
    dinnerOnly: false,
  },
  {
    name: "Sake Avocado Maki",
    price: 1.8,
    category: "sushi",
    id: 41,
    descr: "Lachs & Avocado",
    dinnerOnly: false,
  },
  {
    name: "Kawa Maki",
    price: 1.6,
    category: "sushi",
    id: 42,
    descr: "Lachs gekocht mit Chili-Mayonnaise & Lauch",
    dinnerOnly: false,
  },
  {
    name: "Red California Maki",
    price: 1.8,
    category: "sushi",
    id: 43,
    descr: "Surimi & Avocado mit Red Massago",
    dinnerOnly: false,
  },
  {
    name: "Green California Maki",
    price: 1.8,
    category: "sushi",
    id: 44,
    descr: "Surimi & Avocado mit Green Massago",
    dinnerOnly: false,
  },
  {
    name: "Tempura California Maki",
    price: 1.9,
    category: "sushi",
    id: 45,
    descr: "Avocado & Surimi",
    dinnerOnly: false,
  },
  {
    name: "Tori Karaage Maki",
    price: 2,
    category: "sushi",
    id: 46,
    descr: "Frittiertes Huhn & Gurke",
    dinnerOnly: false,
  },
  {
    name: "Ahiru No Karaage Maki",
    price: 2.1,
    category: "sushi",
    id: 47,
    descr: "Entekross & Gurke",
    dinnerOnly: true,
  },
  {
    name: "Ebi Tempura Maki",
    price: 2.2,
    category: "sushi",
    id: 48,
    descr: "Frittiertes Garnelen & Gurke",
    dinnerOnly: true,
  },
  {
    name: "Sweet Potato Maki",
    price: 2,
    category: "sushi",
    id: 49,
    descr: "Frittierte Süßkartoffel mi Avocado",
    dinnerOnly: true,
  },
  {
    name: "Insideout Sake Roll",
    price: 3,
    category: "sushi",
    id: 52,
    descr: "Lachs, Surimi, Avocado",
    dinnerOnly: true,
  },
  {
    name: "Insideout Unagi Maki",
    price: 3.9,
    category: "sushi",
    id: 53,
    descr: "Aal gegrillt, Surimi mit Avocado",
    dinnerOnly: true,
  },
  {
    name: "Futo Maki",
    price: 2,
    category: "sushi",
    id: 54,
    descr: "Große Mix-Rolle",
    dinnerOnly: false,
  },
  {
    name: "Tempura Futo Maki",
    price: 2.6,
    category: "sushi",
    id: 55,
    descr: "Frittierte Große Mix-Rolle",
    dinnerOnly: false,
  },
  {
    name: "Ura Cabbage Maki",
    price: 2.1,
    category: "sushi",
    id: 60,
    descr: "Rotkohl, Frischkäse, gekochter Lachs mit Red Tobikko",
    dinnerOnly: true,
  },
  {
    name: "Ebi Avocado Temaki",
    price: 3.9,
    category: "sushi",
    id: 62,
    descr: "Garnelen & Avocado",
    dinnerOnly: false,
  },
  {
    name: "Sake Avocado Temaki",
    price: 3.9,
    category: "sushi",
    id: 63,
    descr: "Lachs & Avocado",
    dinnerOnly: false,
  },
  {
    name: "Goma Wakame",
    price: 3.8,
    category: "salad",
    id: 64,
    descr: "Grüner Algensalat",
    dinnerOnly: false,
  },
  {
    name: "Kimchi",
    price: 4.2,
    category: "salad",
    id: 65,
    descr: "scharf eingelegter Chinakohl",
    dinnerOnly: false,
  },
  {
    name: "Kyuri Tsukemono",
    price: 3.5,
    category: "salad",
    id: 66,
    descr: "eingelegter Gurkensalat",
    dinnerOnly: false,
  },
  {
    name: "Ahiru Salat",
    price: 4.8,
    category: "salad",
    id: 67,
    descr: "Frischer Salat mit Entenstücke",
    dinnerOnly: true,
  },
  {
    name: "Sweet potato Fry",
    price: 4.5,
    category: "grill",
    id: 69,
    descr: "Süßkartoffel-Sticks mit Mayonaise",
    dinnerOnly: true,
  },
  {
    name: "Edamame",
    price: 3.9,
    category: "salad",
    id: 70,
    descr: "Grüne junge Sojabohnen",
    dinnerOnly: false,
  },
  {
    name: "Yaki Meshi",
    price: 4.5,
    category: "salad",
    id: 72,
    descr: "Gebratener Reis",
    dinnerOnly: false,
  },
  {
    name: "Yaki Soba",
    price: 4.5,
    category: "salad",
    id: 73,
    descr: "Gebratene Nudeln",
    dinnerOnly: false,
  },
  {
    name: "Gohan",
    price: 3.2,
    category: "salad",
    id: 74,
    descr: "Reis",
    dinnerOnly: false,
  },
  {
    name: "Miso Soup",
    price: 3.8,
    category: "salad",
    id: 75,
    descr: "Mit Tori & Seetang",
    dinnerOnly: false,
  },
  {
    name: "Yasai Men",
    price: 4.5,
    category: "salad",
    id: 76,
    descr: "Vegetarische Nudelsuppe",
    dinnerOnly: false,
  },
  {
    name: "Harumaki",
    price: 2.8,
    category: "grill",
    id: 77,
    descr: "Fleisch & Gemüsefüllung",
    dinnerOnly: false,
  },
  {
    name: "Mini Springroll",
    price: 3.5,
    category: "grill",
    id: 78,
    descr: "Gefüllt mit versch. Gemüse",
    dinnerOnly: false,
  },
  {
    name: "Gyoza Furai",
    price: 3.5,
    category: "grill",
    id: 79,
    descr: "Frittierte Teigtaschen",
    dinnerOnly: false,
  },
  {
    name: "IKa Tempura",
    price: 2.9,
    category: "grill",
    id: 80,
    descr: "Frittierte Tintenfischringe",
    dinnerOnly: false,
  },
  {
    name: "Goma Teba",
    price: 3.5,
    category: "grill",
    id: 82,
    descr: "Frittierte Hähnchenflügel mit Sesam",
    dinnerOnly: false,
  },
  {
    name: "Tori Tempura",
    price: 3.5,
    category: "grill",
    id: 83,
    descr: "Frittierte Hähnchenfilet",
    dinnerOnly: false,
  },
  {
    name: "Tori No Karaage",
    price: 3.9,
    category: "grill",
    id: 84,
    descr: "Frittiertes Hähnchen mit Teriyaki Sauce",
    dinnerOnly: false,
  },
  {
    name: "Tonkatsu",
    price: 3.9,
    category: "grill",
    id: 85,
    descr: "Paniertes Schweinefleisch",
    dinnerOnly: false,
  },
  {
    name: "Crispy Fish",
    price: 4.5,
    category: "grill",
    id: 86,
    descr: "knusprig gebackener Fisch",
    dinnerOnly: false,
  },
  {
    name: "Ahiru No Karaage",
    price: 5.9,
    category: "grill",
    id: 87,
    descr: "Ente knusprig gebacken",
    dinnerOnly: false,
  },
  {
    name: "Tempura Yasai",
    price: 4.9,
    category: "grill",
    id: 88,
    descr: "Frittiertes Gemüse",
    dinnerOnly: false,
  },
  {
    name: "Yasai Korokke",
    price: 3.5,
    category: "grill",
    id: 89,
    descr: "Japanische Gemüse Krokette",
    dinnerOnly: false,
  },
  {
    name: "Tako Yaki",
    price: 3.9,
    category: "grill",
    id: 90,
    descr: "Geb. klößchen mit Oktopusstücken",
    dinnerOnly: true,
  },
  {
    name: "Siu long bao",
    price: 3.2,
    category: "grill",
    id: 91,
    descr: "Kleine Dampf-Täschchen mit Schweinefleisch",
    dinnerOnly: true,
  },
  {
    name: "Gyoza",
    price: 3.8,
    category: "grill",
    id: 92,
    descr: "Teigtaschen mit Hühnerfleischfüllung",
    dinnerOnly: false,
  },
  {
    name: "Yaki Tori",
    price: 2.9,
    category: "grill",
    id: 94,
    descr: "gegrillte Hähnchenspieße",
    dinnerOnly: false,
  },
  {
    name: "Yaki Ika",
    price: 3.2,
    category: "grill",
    id: 96,
    descr: "Tintenfisch vom Teppangrill",
    dinnerOnly: false,
  },
  {
    name: "Sake Yaki",
    price: 4.2,
    category: "grill",
    id: 97,
    descr: "Lachs vom Teppangrill",
    dinnerOnly: false,
  },
  {
    name: "Butaniku Yaki",
    price: 4.5,
    category: "grill",
    id: 100,
    descr: "Schweinefleisch vom Teppangrill",
    dinnerOnly: false,
  },
  {
    name: "Yaki Mushrooms",
    price: 4.5,
    category: "grill",
    id: 101,
    descr: "gegrillte Champignons",
    dinnerOnly: false,
  },
  {
    name: "Ebi Tomato",
    price: 4.9,
    category: "grill",
    id: 102,
    descr: "Schrimps mit Tomatensoße",
    dinnerOnly: false,
  },
  {
    name: "Tori Yaki",
    price: 4.5,
    category: "grill",
    id: 103,
    descr: "Hähnchen gegrillt mit Teriyaki Sauce",
    dinnerOnly: false,
  },
  {
    name: "Tori No Teriyaki",
    price: 4.5,
    category: "grill",
    id: 104,
    descr: "Frittiertes Hähnchen mit Teriyaki Sauce",
    dinnerOnly: false,
  },
  {
    name: "Yaki Yasai",
    price: 4.5,
    category: "grill",
    id: 105,
    descr: "gegrilltes Gemüse",
    dinnerOnly: false,
  },
  {
    name: "Sake Sashimi",
    price: 10,
    category: "side",
    id: 106,
    descr: "Lachs 6 Scheiben,3",
    dinnerOnly: false,
  },
  {
    name: "Maguro Sashimi",
    price: 12,
    category: "side",
    id: 108,
    descr: "Thunfisch 6 Scheiben,4",
    dinnerOnly: false,
  },
  {
    name: "Sashimi No Morawiasa",
    price: 11,
    category: "side",
    id: 109,
    descr: "Sashimi Mix,4",
    dinnerOnly: false,
  },
  {
    name: "Hotategai Kushi",
    price: 5.9,
    category: "side",
    id: 110,
    descr: "gegrillte Jakobsmuscheln,2",
    dinnerOnly: false,
  },
  {
    name: "Unagi Don",
    price: 4.5,
    category: "salad",
    id: 113,
    descr: "Aal gegrillt mit Reis",
    dinnerOnly: true,
  },
  {
    name: "Seafood Soup",
    price: 5.5,
    category: "salad",
    id: 114,
    descr: "mit Meeres-früchten",
    dinnerOnly: true,
  },
  {
    name: "Ramu Kushi",
    price: 4.2,
    category: "grill",
    id: 118,
    descr: "Lammspieß gegrillt sehr scharf",
    dinnerOnly: true,
  },
  {
    name: "Ebi Kushi",
    price: 4.9,
    category: "grill",
    id: 119,
    descr: "gegrillte Garnelenspieße",
    dinnerOnly: true,
  },
  {
    name: "Tomato Bacon Kushi",
    price: 3.2,
    category: "grill",
    id: 120,
    descr: "Cherry Tomaten im Speckmantel gegrillt",
    dinnerOnly: true,
  },
  {
    name: "Ebi Gyoza",
    price: 3.9,
    category: "grill",
    id: 122,
    descr: "Gedämpfte Garnelen - Teigtaschen",
    dinnerOnly: true,
  },
  {
    name: " Hase Bun",
    price: 2.8,
    category: "dessert",
    id: 124,
    descr: " Jap. Gedämpftes Brot mit Süßer Füllung",
    dinnerOnly: true,
  },
  {
    name: "Crab",
    price: 3.8,
    category: "grill",
    id: 125,
    descr: "Panierte Krabben",
    dinnerOnly: true,
  },
  {
    name: "Ebi Korokke",
    price: 3.9,
    category: "grill",
    id: 126,
    descr: "Garnelen Krokette",
    dinnerOnly: true,
  },
  {
    name: "Ebi Tempura",
    price: 4.2,
    category: "grill",
    id: 127,
    descr: "Panierte Garnelen",
    dinnerOnly: true,
  },
  {
    name: "Kohitsuji",
    price: 5.8,
    category: "grill",
    id: 128,
    descr: "Lammkotelett gegrillt",
    dinnerOnly: true,
  },
  {
    name: "Gyu Hirenku",
    price: 4.9,
    category: "grill",
    id: 129,
    descr: "Rinderfilet gegrillt",
    dinnerOnly: true,
  },
  {
    name: "Hirame",
    price: 4.8,
    category: "grill",
    id: 130,
    descr: "Heibutt gegrillt",
    dinnerOnly: true,
  },
  {
    name: "Ahiru  Yaki",
    price: 4.5,
    category: "grill",
    id: 131,
    descr: "Entenbrustfilet mit Zwiebeln vom Teppanyaki",
    dinnerOnly: false,
  },
  {
    name: "Eryngii Yaki",
    price: 4.2,
    category: "grill",
    id: 132,
    descr: "Eryngii Pilze vom Teppangrill",
    dinnerOnly: true,
  },
  {
    name: "Grüner Tee Eis",
    price: 1.6,
    category: "dessert",
    id: 134,
    descr: "",
    dinnerOnly: false,
  },
  {
    name: "Rote Bohnen Eis",
    price: 1.6,
    category: "dessert",
    id: 135,
    descr: "",
    dinnerOnly: false,
  },
  {
    name: "Schwarzer Sesam Eis",
    price: 1.6,
    category: "dessert",
    id: 136,
    descr: "",
    dinnerOnly: false,
  },
  {
    name: "gebackenes Eis",
    price: 4.5,
    category: "dessert",
    id: 137,
    descr: "mit Vanillegeschmack",
    dinnerOnly: true,
  },
  {
    name: "Teriyaki Sauce",
    price: 2,
    category: "sauce",
    id: 142,
    descr: "",
    dinnerOnly: false,
  },
  {
    name: "Süße-Sauer-Scharf-Sauce",
    price: 1.5,
    category: "sauce",
    id: 143,
    descr: "",
    dinnerOnly: false,
  },
  {
    name: "Süße-Sauer-Sauce",
    price: 1.2,
    category: "sauce",
    id: 144,
    descr: "",
    dinnerOnly: false,
  },
  {
    name: "Erdnuss Sauce",
    price: 1.5,
    category: "sauce",
    id: 146,
    descr: "",
    dinnerOnly: false,
  },
  {
    name: "Chili Sauce",
    price: 1.5,
    category: "sauce",
    id: 147,
    descr: "",
    dinnerOnly: false,
  },
  {
    name: "gebackene Banane",
    price: 3.5,
    category: "dessert",
    id: 148,
    descr: "",
    dinnerOnly: false,
  },
  {
    name: "Erdbeer Eis",
    price: 1.6,
    category: "dessert",
    id: 149,
    descr: "",
    dinnerOnly: false,
  },
  {
    name: "Vanille Eis",
    price: 1.5,
    category: "dessert",
    id: 150,
    descr: "",
    dinnerOnly: false,
  },
  {
    name: "Schokoladen Eis",
    price: 1.5,
    category: "dessert",
    id: 151,
    descr: "",
    dinnerOnly: false,
  },
  {
    name: "Tuna Temaki",
    price: 3.9,
    category: "sushi",
    id: 152,
    descr: "Thunfisch, Avocado",
    dinnerOnly: false,
  },
  {
    name: "Spicy Sake Don",
    price: 4.2,
    category: "salad",
    id: 155,
    descr: "Lachs mit Reis, scharf",
    dinnerOnly: true,
  },
  {
    name: "Spicy Sake Gunkan",
    price: 2.5,
    category: "sushi",
    id: 157,
    descr: "Lachs, scharf",
    dinnerOnly: false,
  },
  {
    name: "Sake Cheese Roll",
    price: 2,
    category: "sushi",
    id: 158,
    descr: "Lachs & Frischkäse",
    dinnerOnly: false,
  },
  {
    name: "Insideout Avocado Roll",
    price: 2.8,
    category: "sushi",
    id: 159,
    descr: "Surimi Tempura & Avocado",
    dinnerOnly: true,
  },
  {
    name: "Frischer Salat",
    price: 4.5,
    category: "salad",
    id: 160,
    descr: "Gemischter Salat",
    dinnerOnly: false,
  },
  {
    name: "Sake Sashimi Salat",
    price: 5.2,
    category: "salad",
    id: 161,
    descr: "Frischer Salat mit Lachs veredelt",
    dinnerOnly: true,
  },
  /*{
    name: "Horenso",
    price: 4.6,
    category: "salad",
    id: 163,
    descr: "Spinat mit Sesamdressing",
    dinnerOnly: true
  },*/
  {
    name: "Salmon Inari",
    price: 3.8,
    category: "sushi",
    id: 166,
    descr: "Tofutasche, gekochter Lachs, Avocado,scharf",
    dinnerOnly: true,
  },
  {
    name: "Yaki Udon",
    price: 4.8,
    category: "salad",
    id: 168,
    descr: "Gebratene dicke Reisnudeln",
    dinnerOnly: false,
  },
  {
    name: "Crispy Gyoza",
    price: 3.8,
    category: "grill",
    id: 170,
    descr: "Gebackene Teigtaschen mit Hühnerfleisch",
    dinnerOnly: false,
  },
  {
    name: "Cheese Sticks",
    price: 3.5,
    category: "grill",
    id: 171,
    descr: "Frittierte Käsesticks",
    dinnerOnly: false,
  },
  {
    name: "Panko Ebi",
    price: 3.8,
    category: "grill",
    id: 172,
    descr: "Panierte Garnelen",
    dinnerOnly: false,
  },
  {
    name: "Asuparabeecon",
    price: 4.5,
    category: "grill",
    id: 180,
    descr: "Spinatmit Bacon gegrillt",
    dinnerOnly: true,
  },
  {
    name: "Yaki Mussels",
    price: 4.5,
    category: "grill",
    id: 181,
    descr: "Grünschalmuscheln gegrillt",
    dinnerOnly: true,
  },
  {
    name: "Ebi Yaki",
    price: 4.5,
    category: "grill",
    id: 182,
    descr: "Riesengarnelen vom Teppangrill",
    dinnerOnly: true,
  },
  {
    name: "Bonsai",
    price: 4.9,
    category: "dessert",
    id: 191,
    descr: "Soja Kokos pudding",
    dinnerOnly: true,
  },
  {
    name: "Minidonut",
    price: 1.8,
    category: "dessert",
    id: 194,
    descr: "Vanillecreme gefüllt",
    dinnerOnly: true,
  },
  {
    name: "Baumkuchen",
    price: 2.5,
    category: "dessert",
    id: 195,
    descr: "Pandan",
    dinnerOnly: true,
  },
  {
    name: "Mini rolly",
    price: 1.9,
    category: "dessert",
    id: 197,
    descr: "Erdbeer",
    dinnerOnly: true,
  },
  /*{
    name: "Rocky Road Brownie",
    price: 1.9,
    category: "dessert",
    id: 198,
    descr: "Brownie",
    dinnerOnly: true
  }*/
];
