import React from "react";
import PropTypes from "prop-types";

import Container from "components/styled/container";
import Text from "components/styled/text";

function Hero({ city }) {
  const hours = city === "dortmund" ? 2.5 : 2;

  return (
    <Container className="hero px-6 py-4">
      <div className="mx-4 pb-6 my-6" style={{ maxWidth: 400 }}>
        <Text tag="h3" className="pb-3 text-slim">
          All you can eat
        </Text>
        <Text tag="p" className="mb-1">
          {`Bestellen Sie ${hours} Stunden lang von unserer Karte.`}
        </Text>
        <Text secondary inverse tag="small">
          Pro Bestellrunde max. 6 Items (Mittagsbuffet) / 5 Items (Abendsbuffet)
          pro Person bestellbar
        </Text>
        <Text tag="p" className="mt-3 mb-1">
          Bestellen Sie bitte nur so viel, wie Sie verzehren können:
        </Text>
        <Text secondary inverse tag="small">
          Die Reste - als Folge einer Überbestellung - werden für Sushi und
          Salate mit 1,00 € pro Stück und für warme Speisen mit 2,00 € pro
          Gericht berechnet und der Erlös anschließend für wohltätige Zwecke
          gespendet
        </Text>
      </div>
    </Container>
  );
}

Hero.propTypes = {
  city: PropTypes.oneOf([
    "bochum",
    "dortmund",
    "gelsenkirchen",
    "siegen",
    "lüdenscheid",
  ]),
};

Hero.defaultProps = {
  city: "bochum",
};

export default Hero;
