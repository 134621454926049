import React, { Fragment } from "react";
import PropTypes from "prop-types";

import Footer from "components/footer";
import Header from "components/header";
import HappyHour from "components/happy-hour";
import Hero from "components/hero";
import Section from "components/styled/section";
import useScrollTo from "hooks/use-scroll-to";

import { getLocation } from "shared/constants";
import Contact from "./contact";
import MenuCategories from "./menu-categories";
import OpeningHours from "./opening-hours";
import OrderByTablet from "./order-by-tablet";

function Home(props) {
  const city = getLocation(props.city);

  useScrollTo();

  return (
    <Fragment>
      <Header city={city}>
        <Hero city={city} />
      </Header>
      {city === "siegen" && <HappyHour />}
      <OrderByTablet city={city} />
      <Section header="Unsere Angebote" secondary>
        <MenuCategories />
      </Section>
      <Section header="Öffnungszeit und Preise" id="contact">
        <OpeningHours city={city} />
      </Section>
      <Section header="Anfahrt und Kontakt" secondary>
        <Contact city={city} />
      </Section>
      <Footer city={city} />
    </Fragment>
  );
}

Home.propTypes = {
  city: PropTypes.string,
};

Home.defaultProps = {
  city: undefined,
};

export default React.memo(Home);
