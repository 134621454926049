import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import Columns from "components/styled/columns";
import Container from "components/styled/container";
import Text from "components/styled/text";

function Section({
  children,
  className,
  headerLeft,
  header,
  headerRight,
  inverse,
  secondary,
  stickyHeader,
  tag,
  id,
  ...rest
}) {
  const sectionHeader = (headerLeft || header || headerRight) && (
    <Columns
      alignCenter
      className={classNames("py-6", {
        "sticky-header": stickyHeader,
        "space-between": headerLeft || headerRight,
      })}
      id={id}
    >
      {headerLeft}
      <Text
        tag="small"
        bold
        block
        center
        uppercase
        className="my-6 text-spacing"
      >
        {header}
      </Text>
      {headerRight}
    </Columns>
  );
  return React.createElement(
    tag,
    {
      className: classNames(className, {
        "pb-6": true,
        "bg-inverse": inverse,
        "bg-gray": secondary,
      }),
      ...rest,
    },
    <Container
      className={classNames({
        "pb-6": header !== undefined,
      })}
    >
      {sectionHeader}
      {children}
    </Container>
  );
}

Section.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  header: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  inverse: PropTypes.bool,
  secondary: PropTypes.bool,
  tag: PropTypes.string,
};

Section.defaultProps = {
  children: null,
  className: undefined,
  header: undefined,
  headerRight: null,
  inverse: false,
  secondary: false,
  tag: "section",
};

export default Section;
