import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

function Container({ children, className, size, tag, ...rest }) {
  return React.createElement(
    tag,
    {
      className: classNames(className, "container", `grid-${size}`),
      ...rest
    },
    children
  );
}

Container.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  size: PropTypes.oneOf(["xs", "sm", "md", "lg", "xl"]),
  tag: PropTypes.string
};

Container.defaultProps = {
  children: null,
  className: "px-4 py-6",
  size: "xl",
  tag: "div"
};

export default Container;
