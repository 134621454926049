import React, { Fragment } from "react";
import PropTypes from "prop-types";

import { locations } from "shared/constants";
import Text from "components/styled/text";
import Layout from "components/styled/layout";

function Field({ title, terms }) {
  return (
    <Layout className="mb-4">
      {title && (
        <Text block secondary>
          {title}
        </Text>
      )}
      {terms.map((term) => (
        <Text key={term} block>
          {term}
        </Text>
      ))}
    </Layout>
  );
}

function Imprint({ city }) {
  const restaurant = locations[city];
  const { address, owner, tel, vatId, vatNumber, postcode, email } =
    restaurant || {};
  return (
    <Fragment>
      <Field title="Angaben gemäß § 5 TMG" terms={[]} />
      <Field title="Yumini" terms={[address, postcode]} />
      {city !== "siegen" && <Field title="Vertreten durch:" terms={[owner]} />}
      {city !== "siegen" && (
        <Field title="Rechtsform:" terms={["Einzelunternehmen"]} />
      )}
      <Field title="Kontakt:" terms={[`Telefon: ${tel}`, `E-Mail: ${email}`]} />
      {city !== "siegen" && (
        <Field terms={[`USt-IdNr: ${vatId}`, `UmSt.Nr: ${vatNumber}`]} />
      )}
    </Fragment>
  );
}

Imprint.defaultProps = {
  city: "bochum",
};

Imprint.propTypes = {
  city: PropTypes.string,
};

export default Imprint;
