import { useEffect } from "react";

export default function useScrollTo() {
  useEffect(() => {
    const targetElement = document.getElementById(
      window.location.hash.slice(1)
    );

    if (targetElement) {
      targetElement.scrollIntoView(true);
    } else {
      window.scrollTo(0, 0);
    }
  }, []);
}
