export default [
  {
    name: "Sake",
    price: 1.6,
    category: "sushi",
    id: 1,
    descr: "Lachs",
    dinnerOnly: false,
  },
  {
    name: "Maguro",
    price: 1.8,
    category: "sushi",
    id: 2,
    descr: "Thunfisch",
    dinnerOnly: false,
  },
  {
    name: "Mutsu",
    price: 1.5,
    category: "sushi",
    id: 3,
    descr: "Butterfisch",
    dinnerOnly: false,
  },
  {
    name: "Crispy Sake Nigiri",
    price: 2.0,
    category: "sushi",
    id: 4,
    descr: "Frittierter Nigiri mit Lachscreme",
    dinnerOnly: true,
  },
  {
    name: "Crispy Tuna Nigiri",
    price: 2.0,
    category: "sushi",
    id: "4A",
    descr: " Frittierte Nigiri mit Scharf Thunfisch",
    dinnerOnly: true,
  },
  {
    name: "Ebi",
    price: 1.8,
    category: "sushi",
    id: 5,
    descr: "Garnelen",
    dinnerOnly: false,
  },
  {
    name: "Kanikama",
    price: 1.3,
    category: "sushi",
    id: 6,
    descr: "Surimi",
    dinnerOnly: false,
  },
  {
    name: "Avocado",
    price: 1.3,
    category: "sushi",
    id: 7,
    descr: "Avocado",
    dinnerOnly: false,
  },
  {
    name: "Tamago",
    price: 1.3,
    category: "sushi",
    id: 8,
    descr: "Omlett",
    dinnerOnly: false,
  },
  {
    name: "Kinusaya",
    price: 1.3,
    category: "sushi",
    id: 9,
    descr: "Pochierte Kaiserschoten",
    dinnerOnly: false,
  },
  {
    name: "Inari",
    price: 1.5,
    category: "sushi",
    id: 10,
    descr: "Tofutasche",
    dinnerOnly: false,
  },
  {
    name: "IKa",
    price: 1.5,
    category: "sushi",
    id: 11,
    descr: "Tintenfisch",
    dinnerOnly: false,
  },
  {
    name: "Tako",
    price: 1.8,
    category: "sushi",
    id: 12,
    descr: "Oktopus",
    dinnerOnly: false,
  },
  {
    name: "Amaebi",
    price: 1.8,
    category: "sushi",
    id: 13,
    descr: "Süßwasserschrimps",
    dinnerOnly: true,
  },
  {
    name: "Hokkigai",
    price: 2.2,
    category: "sushi",
    id: 14,
    descr: "Nordmuscheln",
    dinnerOnly: true,
  },
  {
    name: "Hotategai",
    price: 2.2,
    category: "sushi",
    id: 15,
    descr: "Jakobsmuscheln",
    dinnerOnly: true,
  },
  {
    name: "Unagi",
    price: 2.2,
    category: "sushi",
    id: 16,
    descr: "Aal gegrillt",
    dinnerOnly: true,
  },
  {
    name: "Sake Aburi",
    price: 1.9,
    category: "sushi",
    id: 17,
    descr: "Lachs flambiert",
    dinnerOnly: true,
  },
  {
    name: "Maguro Aburi",
    price: 2.1,
    category: "sushi",
    id: 18,
    descr: "Thunfisch flambiert",
    dinnerOnly: true,
  },
  {
    name: "Gyu Tataki Nigiri",
    price: 1.8,
    category: "sushi",
    id: "18A",
    descr: "Rindfleisch. mariniert. jap. Gewürz. leicht scharf",
    dinnerOnly: false,
  },
  {
    name: " Gyu Tataki Aburi Nigiri",
    price: 1.9,
    category: "sushi",
    id: "18B",
    descr: "Rindfleisch. Flambiert. jap. Gewürz. leicht scharf",
    dinnerOnly: true,
  },
  {
    name: "Sake Cheese Aburi",
    price: 1.9,
    category: "sushi",
    id: "18C",
    descr: " Lachs mit Käse flambiert",
    dinnerOnly: true,
  },
  {
    name: "Gyu Tataki Cheese Aburi",
    price: 2.0,
    category: "sushi",
    id: "18D",
    descr: "Rindfleisch mit Käse flambiert, leicht scharf",
    dinnerOnly: true,
  },
  {
    name: "Goma Wakame",
    price: 1.8,
    category: "sushi",
    id: 19,
    descr: "Algen",
    dinnerOnly: false,
  },
  {
    name: "Tsuna-Gunkan",
    price: 2,
    category: "sushi",
    id: 20,
    descr: "Thunfischcreme",
    dinnerOnly: false,
  },
  {
    name: "Sakura-Gunkan",
    price: 1.6,
    category: "sushi",
    id: 21,
    descr: "Surimi. Rotkohl & Mais",
    dinnerOnly: false,
  },
  {
    name: " Fresh Cheese Gunkan",
    price: 1.9,
    category: "sushi",
    id: "21A",
    descr: "Frischkäse Avocado Gurkenstreifen",
    dinnerOnly: true,
  },
  {
    name: "Red-Massago-Gunkan",
    price: 2,
    category: "sushi",
    id: 22,
    descr: "Fischrogen",
    dinnerOnly: false,
  },
  {
    name: "Green-Massago-Gunkan",
    price: 2,
    category: "sushi",
    id: 23,
    descr: "Fischrogen",
    dinnerOnly: false,
  },
  {
    name: "Spicy Tuna",
    price: 2,
    category: "sushi",
    id: 24,
    descr: "Scharfer Thunfisch. Lauch. Dünn Gurkenstreifen umgewickelt",
    dinnerOnly: true,
  },
  {
    name: "Sake Gunkan",
    price: 2.0,
    category: "sushi",
    id: 25,
    descr: " Lachscreme, dünn Gurkenstreifen umgewickelt",
    dinnerOnly: true,
  },
  {
    name: "Ikura",
    price: 3.5,
    category: "sushi",
    id: 26,
    descr: "Lachskaviar",
    dinnerOnly: true,
  },
  {
    name: " Räucherlachs-Gunkan",
    price: 2,
    category: "sushi",
    id: "26A",
    descr: "Räucherlachs. Lauch",
    dinnerOnly: true,
  },
  {
    name: "Kappa Maki",
    price: 1.5,
    category: "sushi",
    id: 27,
    descr: "Gurken",
    dinnerOnly: false,
  },

  {
    name: "Avocado Maki",
    price: 1.5,
    category: "sushi",
    id: 29,
    descr: "Avocado",
    dinnerOnly: false,
  },
  {
    name: "Schinko Maki",
    price: 1.5,
    category: "sushi",
    id: 30,
    descr: "eingelegter Rettich",
    dinnerOnly: false,
  },
  {
    name: "Sake Maki",
    price: 1.6,
    category: "sushi",
    id: 33,
    descr: "Lachs",
    dinnerOnly: false,
  },
  {
    name: "Mutsu Maki",
    price: 1.6,
    category: "sushi",
    id: 34,
    descr: "Butterfisch.scharf",
    dinnerOnly: false,
  },
  {
    name: "Tekka Maki",
    price: 1.9,
    category: "sushi",
    id: 35,
    descr: "Thunfisch",
    dinnerOnly: false,
  },
  {
    name: "Tsuna Maki",
    price: 1.8,
    category: "sushi",
    id: 36,
    descr: "Thunfischcreme. Gekocht",
    dinnerOnly: false,
  },

  {
    name: "Fresh Cheese Roll",
    price: 1.8,
    category: "sushi",
    id: 39,
    descr: "Frischkäse & Gurke",
    dinnerOnly: false,
  },
  {
    name: "Fresh Cheese Sake Roll",
    price: 1.8,
    category: "sushi",
    id: 40,
    descr: "Lachs & Frischkäse",
    dinnerOnly: false,
  },
  {
    name: "Sake Avocado Maki",
    price: 1.5,
    category: "sushi",
    id: 41,
    descr: "Lachs & Avocado",
    dinnerOnly: false,
  },
  {
    name: "Kawa Maki",
    price: 1.3,
    category: "sushi",
    id: 42,
    descr: "Lachs gekocht & Avocado",
    dinnerOnly: false,
  },
  {
    name: "Red California Maki",
    price: 1.5,
    category: "sushi",
    id: 43,
    descr: "Surimi & Avocado mit Red Massago",
    dinnerOnly: false,
  },
  {
    name: "Green California Maki",
    price: 1.5,
    category: "sushi",
    id: 44,
    descr: "Surimi & Avocado mit Green Massago",
    dinnerOnly: false,
  },
  {
    name: "Tempura California Maki",
    price: 1.6,
    category: "sushi",
    id: 45,
    descr: "Avocado & Surimi",
    dinnerOnly: false,
  },
  {
    name: "Tori Karaage Maki",
    price: 1.8,
    category: "sushi",
    id: 46,
    descr: "Frittiertes Huhn & Gurke",
    dinnerOnly: false,
  },
  {
    name: "Ahiru No Karaage Maki",
    price: 1.9,
    category: "sushi",
    id: 47,
    descr: "Entekross & Gurke",
    dinnerOnly: true,
  },
  {
    name: "Ebi Tempura Maki",
    price: 1.9,
    category: "sushi",
    id: 48,
    descr: "Frittiertes Garnelen &amp",
    dinnerOnly: false,
  },
  {
    name: "Ebi Cheese Maki",
    price: 1.9,
    category: "sushi",
    id: "48A",
    descr: "Frittierte Garnele &Gruke, Käse , Röstzwiebeln",
    dinnerOnly: false,
  },
  {
    name: "Schwarzer Reis, Frittierte Garnele, Lachscreme",
    price: 2.5,
    category: "sushi",
    id: 49,
    descr: "Venere Reis aus Italien",
    dinnerOnly: true,
  },
  {
    name: " Schwarzer Reis, Frittierte Garnele, Frische Käse",
    price: 2.2,
    category: "sushi",
    id: 50,
    descr: "Venere Reis aus Italien",
    dinnerOnly: true,
  },
  {
    name: "Chicken Mango Maki",
    price: 1.9,
    category: "sushi",
    id: 51,
    descr: "Frittiertes Huhn Mango mit Mango Sosse",
    dinnerOnly: true,
  },
  {
    name: "Insideout Sake Roll",
    price: 2.4,
    category: "sushi",
    id: 52,
    descr: "Lachs. Surimi. Avocado",
    dinnerOnly: true,
  },
  {
    name: "Insideout Unagi Maki",
    price: 2.8,
    category: "sushi",
    id: 53,
    descr: "Aal gegrillt. Surimi mit Avocado",
    dinnerOnly: true,
  },

  {
    name: "Tempura Futo Maki",
    price: 2.2,
    category: "sushi",
    id: 55,
    descr: "Frittierte Große Mix-Rolle",
    dinnerOnly: false,
  },
  {
    name: "Tempura Futo Maki Spicy",
    price: 2.4,
    category: "sushi",
    id: 56,
    descr: "Frittierte Rolle mit Spicy Mayo und geröstet Zwiebel",
    dinnerOnly: false,
  },
  {
    name: "Red Cabbage Chumaki",
    price: 1.9,
    category: "sushi",
    id: 57,
    descr: "Rotkohl,Salat,Frischkäse mit gekochter Lachs",
    dinnerOnly: false,
  },
  {
    name: "Yasai Temaki",
    price: 3.2,
    category: "sushi",
    id: 61,
    descr: "Vegetarisch. Gurke. Avocado. Algensalat. Salat",
    dinnerOnly: false,
  },
  {
    name: "Ebi Avocado Temaki",
    price: 3.2,
    category: "sushi",
    id: 62,
    descr: "Garnelen & Avocado",
    dinnerOnly: false,
  },
  {
    name: "Sake Avocado Temaki",
    price: 3.2,
    category: "sushi",
    id: 63,
    descr: "Lachs & Avocado",
    dinnerOnly: false,
  },
  {
    name: "Goma Wakame",
    price: 3.5,
    category: "salad",
    id: 64,
    descr: "Grüner Algensalat",
    dinnerOnly: false,
  },
  {
    name: "Kimchi",
    price: 2.9,
    category: "salad",
    id: 65,
    descr: "scharf eingelegter Chinakohl",
    dinnerOnly: false,
  },
  {
    name: "Kyuri Tsukemono",
    price: 2.5,
    category: "salad",
    id: 66,
    descr: "eingelegter Gurkensalat",
    dinnerOnly: false,
  },
  {
    name: "Erdbeer-Frischkäse-Torte",
    price: 1,
    category: "dessert",
    id: 68,
    descr: "",
    dinnerOnly: true,
  },

  {
    name: "Edamame",
    price: 2.9,
    category: "salad",
    id: 70,
    descr: "Grüne junge Sojabohnen",
    dinnerOnly: false,
  },
  {
    name: "Yaki Meshi",
    price: 3.5,
    category: "salad",
    id: 72,
    descr: "Gebratener Reis mit Ei",
    dinnerOnly: false,
  },
  {
    name: "Yaki Soba",
    price: 3.5,
    category: "salad",
    id: 73,
    descr: "Gebratene Nudeln",
    dinnerOnly: false,
  },
  {
    name: "Gohan",
    price: 2,
    category: "salad",
    id: 74,
    descr: "Reis",
    dinnerOnly: false,
  },
  {
    name: "Miso Soup",
    price: 2.9,
    category: "salad",
    id: 75,
    descr: "Mit Tori & Seetang",
    dinnerOnly: false,
  },
  {
    name: "Yasai Men",
    price: 3.5,
    category: "salad",
    id: 76,
    descr: "Vegetarische Nudelsuppe",
    dinnerOnly: false,
  },
  {
    name: "Cheese Harumaki",
    price: 2.5,
    category: "grill",
    id: 77,
    descr: "Käse.Hühnerfleisch Füllung.Knusprig",
    dinnerOnly: true,
  },
  {
    name: "Mini Springroll",
    price: 2.5,
    category: "grill",
    id: 78,
    descr: "Gefüllt mit versch. Gemüse",
    dinnerOnly: false,
  },
  {
    name: "Gyoza Furai",
    price: 3.5,
    category: "grill",
    id: 79,
    descr: "Frittierte Teigtaschen mit Huhn",
    dinnerOnly: false,
  },
  {
    name: "IKa Tempura",
    price: 2.5,
    category: "grill",
    id: 80,
    descr: "Frittierte Tintenfischringe",
    dinnerOnly: false,
  },

  {
    name: "Goma Teba",
    price: 1.6,
    category: "grill",
    id: 82,
    descr: "Frittierte Hähnchenflügel mit Sesam",
    dinnerOnly: false,
  },
  {
    name: "Tori Tempura",
    price: 3.5,
    category: "grill",
    id: 83,
    descr: "Frittierte Hähnchenfilet",
    dinnerOnly: false,
  },
  {
    name: "Chicken Balls",
    price: 3.2,
    category: "grill",
    id: 84,
    descr: "Frittiertes Hähnchen mit Teriyaki Sauce",
    dinnerOnly: false,
  },
  {
    name: "Tori Curry",
    price: 3.5,
    category: "grill",
    id: 85,
    descr: "Huhn-Rot Curry mit Kokosmilch",
    dinnerOnly: false,
  },
  {
    name: "Crispy Fish",
    price: 3.9,
    category: "grill",
    id: 86,
    descr: "knusprig gebackener Fisch",
    dinnerOnly: false,
  },
  {
    name: "Ahiru No Karaage",
    price: 4.9,
    category: "grill",
    id: 87,
    descr: "Ente knusprig gebacken",
    dinnerOnly: false,
  },
  {
    name: "Tempura Yasai",
    price: 3.5,
    category: "grill",
    id: 88,
    descr: "Frittiertes Gemüse",
    dinnerOnly: false,
  },
  {
    name: "Yasai Korokke",
    price: 2.5,
    category: "grill",
    id: 89,
    descr: "Japanische Gemüse Krokette",
    dinnerOnly: false,
  },
  {
    name: "Tako Yaki",
    price: 3.2,
    category: "grill",
    id: 90,
    descr: "Geb. klößchen mit Oktopusstücken",
    dinnerOnly: false,
  },
  {
    name: "Gegrillte Rotbarschfilet",
    price: 3.2,
    category: "grill",
    id: 91,
    descr: "Gegrillte Rotbarschfilet",
    dinnerOnly: false,
  },
  {
    name: "Gyoza",
    price: 3,
    category: "grill",
    id: 92,
    descr: "Teigtaschen mit Hühnerfleischfüllung",
    dinnerOnly: false,
  },
  {
    name: "Yaki Tori",
    price: 1.5,
    category: "grill",
    id: 94,
    descr: "gegrillte Hähnchenspieße",
    dinnerOnly: false,
  },
  {
    name: "Yaki Ika",
    price: 3.2,
    category: "grill",
    id: 96,
    descr: "Tintenfisch vom Teppangrill",
    dinnerOnly: false,
  },
  {
    name: "Sake Yaki",
    price: 3.5,
    category: "grill",
    id: 97,
    descr: "Lachs vom Teppangrill",
    dinnerOnly: false,
  },
  {
    name: "Butaniku Yaki",
    price: 3.2,
    category: "grill",
    id: 100,
    descr: "Schweinefleisch vom Teppangrill",
    dinnerOnly: false,
  },
  {
    name: "Yaki Mushrooms",
    price: 3,
    category: "grill",
    id: 101,
    descr: "Champignons",
    dinnerOnly: false,
  },
  {
    name: "Ebi Tomato",
    price: 4.2,
    category: "grill",
    id: 102,
    descr: "Schrimps mit Tomatensoße",
    dinnerOnly: false,
  },
  {
    name: "Tori Yaki",
    price: 3.5,
    category: "grill",
    id: 103,
    descr: "Hähnchen gegrillt mit Teriyaki Sauce",
    dinnerOnly: false,
  },
  {
    name: "Tori No Teriyaki",
    price: 3.9,
    category: "grill",
    id: 104,
    descr: "Frittiertes Hähnchen mit Teriyaki Sauce",
    dinnerOnly: false,
  },
  {
    name: "Yaki Yasai",
    price: 3,
    category: "grill",
    id: 105,
    descr: "gegrilltes Gemüse",
    dinnerOnly: false,
  },
  {
    name: "Sake Sashimi",
    price: 8,
    category: "side",
    id: 106,
    descr: "Lachs 9 Sheiben",
    dinnerOnly: false,
  },
  {
    name: "Butterfisch Sashimi",
    price: 8,
    category: "side",
    id: 107,
    descr: " Butterfisch 9 Scheiben",
    dinnerOnly: false,
  },
  {
    name: "Maguro Sashimi",
    price: 10,
    category: "side",
    id: 108,
    descr: "Thunfisch 9 Sheiben",
    dinnerOnly: false,
  },
  {
    name: "Sashimi No Morawiasa",
    price: 10,
    category: "side",
    id: 109,
    descr: "Sashimi Mix 9 Sheiben",
    dinnerOnly: false,
  },
  {
    name: "Hotategai Kushi",
    price: 5.9,
    category: "side",
    id: 110,
    descr: "gegrillte Jakobsmuscheln",
    dinnerOnly: false,
  },
  {
    name: "Unagi Don",
    price: 4.5,
    category: "salad",
    id: 113,
    descr: "Aal gegrillt mit Reis",
    dinnerOnly: true,
  },
  {
    name: "Meeresfrücht Suppe",
    price: 4.5,
    category: "salad",
    id: 114,
    descr: "Sauer Scharf Suppe mit Meeresfrüchten und Kokosmilch",
    dinnerOnly: true,
  },
  {
    name: "Ramu Kushi",
    price: 1.9,
    category: "grill",
    id: 118,
    descr: "Lammspieß gegrillt sehr scharf",
    dinnerOnly: true,
  },
  {
    name: "Ebi Kushi",
    price: 3.6,
    category: "grill",
    id: 119,
    descr: "gegrillte Garnelenspieße",
    dinnerOnly: true,
  },
  {
    name: "Tomato Bacon Kushi",
    price: 1.6,
    category: "grill",
    id: 120,
    descr: "Cherry Tomaten im Speckmantel gegrillt",
    dinnerOnly: true,
  },
  {
    name: "Panko Ebi",
    price: 2.2,
    category: "grill",
    id: 121,
    descr: "Panierte Garnelen",
    dinnerOnly: false,
  },
  {
    name: "Ebi Gyoza",
    price: 2.4,
    category: "grill",
    id: 122,
    descr: "Gedämpft Garnel-Teigtasche. Essig Sosse",
    dinnerOnly: true,
  },
  {
    name: "Kaninchen Bun",
    price: 1.5,
    category: "grill",
    id: 124,
    descr: "Jap. Gedämpftes Brot mit süßer Füllung",
    dinnerOnly: true,
  },
  {
    name: "Crab",
    price: 2.2,
    category: "grill",
    id: 125,
    descr: "Panierte Krabben",
    dinnerOnly: true,
  },
  {
    name: "Ebi Korokke",
    price: 3.2,
    category: "grill",
    id: 126,
    descr: "Garnelen Krokette",
    dinnerOnly: true,
  },
  {
    name: "Ebi Tempura",
    price: 3.5,
    category: "grill",
    id: 127,
    descr: "Panierte Garnelen",
    dinnerOnly: true,
  },
  {
    name: "Kohitsuji",
    price: 4.9,
    category: "grill",
    id: 128,
    descr: "Lammkotelett gegrillt",
    dinnerOnly: true,
  },
  {
    name: "Gyu Hirenku",
    price: 4.2,
    category: "grill",
    id: 129,
    descr: "Rinderfilet gegrillt mit schwarz Pfeffer",
    dinnerOnly: true,
  },
  {
    name: "Hirame",
    price: 3.9,
    category: "grill",
    id: 130,
    descr: "Heibutt gegrillt",
    dinnerOnly: true,
  },
  {
    name: "Ahiru Yaki",
    price: 3.9,
    category: "grill",
    id: 131,
    descr: " Entenbrustfilet mit Zwiebeln vom Teppangrill",
    dinnerOnly: false,
  },
  {
    name: "Eryngii Yaki",
    price: 3.9,
    category: "grill",
    id: 132,
    descr: "Kräuterseitling vom Teppangrill",
    dinnerOnly: true,
  },
  {
    name: "Grüner Tee Eis",
    price: 1.2,
    category: "dessert",
    id: 134,
    descr: "",
    dinnerOnly: true,
  },
  {
    name: "Lychee Eis",
    price: 1.2,
    category: "dessert",
    id: 135,
    descr: "",
    dinnerOnly: true,
  },
  {
    name: "Schwarzer Sesam Eis",
    price: 1.2,
    category: "dessert",
    id: 136,
    descr: "",
    dinnerOnly: true,
  },
  {
    name: "gebackenes Eis",
    price: 3.5,
    category: "dessert",
    id: 137,
    descr: "mit Vanillegeschmack",
    dinnerOnly: true,
  },
  {
    name: "Furütsu",
    price: 3.5,
    category: "dessert",
    id: 139,
    descr: "Frisches Obst",
    dinnerOnly: true,
  },
  {
    name: "Teriyaki",
    price: 0.5,
    category: "sauce",
    id: 142,
    descr: "Sauce",
    dinnerOnly: false,
  },
  {
    name: "Süße-Sauer-Scharf-Sauce",
    price: 0.5,
    category: "sauce",
    id: 143,
    descr: "",
    dinnerOnly: false,
  },
  {
    name: "Süße-Sauer-Sauce",
    price: 0.5,
    category: "sauce",
    id: 144,
    descr: "",
    dinnerOnly: false,
  },
  {
    name: "Erdnuss Sauce",
    price: 0.5,
    category: "sauce",
    id: 146,
    descr: "",
    dinnerOnly: false,
  },
  {
    name: "Chili Sauce",
    price: 0.5,
    category: "sauce",
    id: 147,
    descr: "",
    dinnerOnly: false,
  },
  {
    name: "gebackene Banane",
    price: 2.9,
    category: "dessert",
    id: 148,
    descr: "",
    dinnerOnly: false,
  },
  {
    name: "Vanille Eis",
    price: 1.2,
    category: "dessert",
    id: 150,
    descr: "",
    dinnerOnly: false,
  },
  {
    name: "Schokoladen Eis",
    price: 1.2,
    category: "dessert",
    id: 151,
    descr: "",
    dinnerOnly: false,
  },
  {
    name: "Mango Eis",
    price: 1.2,
    category: "dessert",
    id: 152,
    descr: "",
    dinnerOnly: false,
  },
  {
    name: "Asuparabeecon",
    price: 3.2,
    category: "grill",
    id: 160,
    descr: "Spargel mit Bacon gegrillt",
    dinnerOnly: true,
  },
  {
    name: "Yaki Mussels",
    price: 2.6,
    category: "grill",
    id: 161,
    descr: "Grünschalmuscheln gegrillt",
    dinnerOnly: true,
  },
  {
    name: "Thunfisch Salat",
    price: 3.9,
    category: "salad",
    id: 162,
    descr: "Thunfisch Salat. jap.Sesam Dressing",
    dinnerOnly: true,
  },
  {
    name: "Horenso Goma",
    price: 3.2,
    category: "salad",
    id: 163,
    descr: "Spinat mit jap.Sesam Dressing",
    dinnerOnly: true,
  },
  {
    name: "Süsskartoffel",
    price: 3.2,
    category: "grill",
    id: 181,
    descr: " Süsskartoffel Sticks mit Dip",
    dinnerOnly: true,
  },
  {
    name: "Kokosnuss Bällchen mit Kokosnuss Füllung",
    price: 1.8,
    category: "dessert",
    id: 188,
    dinnerOnly: true,
  },
  {
    name: "Rocky Road Brownie",
    price: 1.6,
    category: "dessert",
    id: 189,
    dinnerOnly: true,
  },
];
