import React from "react";
import PropTypes from "prop-types";

function BackgroundContainer({
  children,
  className,
  image,
  position,
  size,
  style
}) {
  return (
    <div
      className={className}
      style={{
        backgroundImage: `url(/images/${image})`,
        height: "100%",
        backgroundSize: size,
        backgroundRepeat: "no-repeat",
        backgroundPosition: position,
        ...style
      }}
    >
      {children}
    </div>
  );
}

BackgroundContainer.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  image: PropTypes.string.isRequired,
  position: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  style: PropTypes.object
};

BackgroundContainer.defaultProps = {
  children: null,
  className: undefined,
  position: "center",
  size: "cover",
  style: {}
};

export default BackgroundContainer;
